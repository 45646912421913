/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { API } from "@roambee/client-utility";
import * as React from "react";
import { useEffect, useState } from "react";
// @ts-ignore
import { Table, Pagination } from "@roambee/client-styleguide";
import { Skeleton, Typography } from "@mui/material";

type Node = {
	name: string;
	uuid: string;
	visibility: string;
	issues: string;
	address: string;
	area: number;
	overlaps: string;
	intersects: string;
};

function NodesTable() {
	const columns = [
		{
			field: "uuid",
			headerName: "UUID",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "name",
			headerName: "Name",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "address",
			headerName: "Address",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "visibility",
			headerName: "Visibility",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "area",
			headerName: "Area",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "issues",
			headerName: "Checks",
			// width: 12,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "details",
			headerName: "Details",
			width: 12,
			isSortable: false,
			isSearchable: false
		}
	];
	const [data, setData] = useState<[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalNodes, setTotalNodes] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [currentData, setCurrentData] = useState<Node[]>([]);

	const fetchData = async () => {
		try {
			const nodesData = await API("GET", `/account/node-intel/`);
			nodesData.data = nodesData?.data?.map((pageData) => {
				return pageData.map((node) => ({ ...node, details: `Overlaps:${node["overlaps"].slice(0, -2) || "None"}\nIntersects:${node["intersects"].slice(0, -2) || "None"}` }));
			});
			setData(nodesData?.data);
			setTotalPages(nodesData?.totalPages);
			setCurrentData(Object.values(nodesData?.data?.[currentPage - 1] || {}));
			setTotalNodes(nodesData?.totalNodes);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};

	const updateData = () => {
		setCurrentData(Object.values(data[currentPage - 1] || {}));
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		if (currentData.length) updateData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<section id="node-table">
			<Typography variant="h5" sx={{ justifyContent: "center", display: "flex" }}>
				This account has {totalNodes} nodes.
			</Typography>
			{!loading && (
				<div className="group-table">
					<Table showFilters={false} isSelectable={false} columns={columns} visibleColumns={columns} data={currentData} total={totalNodes} page={currentPage} pageSize={currentData?.length} pagination={false} sticky={true} />
					<Pagination className="node-table-pagination" total={totalPages} current={currentPage} onChangePage={(value: number) => setCurrentPage(value)} />
				</div>
			)}
			{loading && <Skeleton className="skeleton"></Skeleton>}
		</section>
	);
}

export default NodesTable;
